import React, { Fragment } from 'react';
import Carousel from '../Carousel';
import {
  MainDiv,
  Title,
  Testimonie,
  ImageCircle,
  StyledCarousel,
  StyledSlider,
  StyledSlide,
  StyledDotGroup,
  Company
} from './style';

// const testimonies = [
//   {
//     text:
//       'This is the perfect solution for our business. Well-coordinated teamwork and knowledge in the field of our business created the perfect product for the development of the company. We have already seen amazing results.',
//     name: '',
//     company: '',
//     imgURL: '',
//   },
//   {
//     text:
//       'This is the perfect solution for our business. Well-coordinated teamwork and knowledge in the field of our business created the perfect product for the development of the company. We have already seen amazing results.',
//     name: 'Magdalena Reyes',
//     company: 'XXX',
//     imgURL: '',
//   },
//   {
//     text:
//       'This is the perfect solution for our business. Well-coordinated teamwork and knowledge in the field of our business created the perfect product for the development of the company. We have already seen amazing results.',
//     name: 'Magdalena Armas',
//     company: 'XXX',
//     imgURL: 'a',
//   },
// ];

const renderTestimonies = (testimonies) => {
  return testimonies.map(testimonie => {
    return (
      <Fragment key={testimonie.company}>
        {/* <ImageCircle imageURL={testimonie.imageURL} /> */}
        <Testimonie>{testimonie.text}</Testimonie>
        <Company>{testimonie.company}</Company>
      </Fragment>
    );
  });
};

const Testimonies = ({testimonies}) => (
  <MainDiv>
     <div style={{ display: "flex", flexDirection: "row" }}>
          <Title className="italic">Testimonios</Title>{" "}
        </div>
    <Carousel
      totalSlides={testimonies.length}
      StyledCarousel={StyledCarousel}
      StyledSlider={StyledSlider}
      StyledSlide={StyledSlide}
      StyledDotGroup={StyledDotGroup}
    >
      {renderTestimonies(testimonies)}
    </Carousel>
  </MainDiv>
);

export default Testimonies;
