import React, { useState } from "react";
import {
  MainDiv,
  LinkDiv,
  LinkImg,
  IconDiv,
} from "./styles";
import Lograf from "../../assets/img/lografLogo.svg";
import Facebook from "../../assets/img/facebook.js";
import Instagram from "../../assets/img/instagram.js";
import Linkedin from "../../assets/img/linkedin.js";
import Drawer from "@material-ui/core/Drawer";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import { Link } from "react-router-dom";
import Hamburguer from "../../assets/img/hamburguer.png";
import Close from "../../assets/img/close.png";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));
const MobileHeader = () => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  return (
    <div>
      <MainDiv>
        <div onClick={() => setOpen(true)}>
          <img alt="img-alt-p" src={Hamburguer} style={{ width: "3rem" }} />
        </div>
        <Link to="/">

          <img alt="img-alt-p" src={Lograf} style={{ width: "10rem" }} />

        </Link>
      </MainDiv>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        onClose={() => setOpen(false)}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={() => setOpen(false)}>
            <div>
              <img alt="img-alt-p" src={Close} style={{ width: "1rem" }} />
            </div>
          </IconButton>
        </div>
        <LinkDiv onClick={() => setOpen(false)}>
          <Link to="/">Inicio</Link>
        </LinkDiv>
        <LinkDiv onClick={() => setOpen(false)}>
          <Link to="/design">Diseño</Link>
        </LinkDiv>
        <LinkDiv onClick={() => setOpen(false)}>
          <Link to="/web">Web</Link>
        </LinkDiv>
        <LinkDiv onClick={() => setOpen(false)}>
          <Link to="/ecommerce">Ecommerce</Link>
        </LinkDiv>
        <LinkDiv onClick={() => setOpen(false)}>
          <Link to="/contacto">Charlemos</Link>
        </LinkDiv>
        <LinkDiv onClick={() => setOpen(false)}>
          <a href="https://blog.lografdesign.com/" target={"blank"} rel="noopener noreferrer">Blog</a>
        </LinkDiv>

        <LinkDiv>
            <Link to="/gift">Regalitos</Link>
          </LinkDiv>
        <IconDiv>
          <a href="https://www.instagram.com/lograf.design/" rel="noopener noreferrer" target="_blank">
            <LinkImg>
              <Instagram fill="#f3b52a" style={{ width: 30.5 }} />
            </LinkImg>
          </a>
          <a href="https://www.facebook.com/lograf.dg" rel="noopener noreferrer" target="_blank">
            <LinkImg>
              <Facebook fill="#f3b52a" style={{ width: 13.5 }} />
            </LinkImg>
          </a>
          <a
           href="https://www.linkedin.com/company/65335206/admin/"
           rel="noopener noreferrer"
           target="_blank">
            <LinkImg>
              <Linkedin fill="#f3b52a" style={{ width: 30.5 }} />
            </LinkImg>
          </a>
        </IconDiv>
      </Drawer>
    </div>
  );
};

export default MobileHeader;
