import styled from "styled-components";
import { theme } from "../theme";

export const MainDiv = styled.nav`
  position: sticky;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${theme.themeColor};
  font-family: "MontserratSemiBold";
  color: ${theme.white};
  padding: 1.2rem;
  @media (max-width: 768px) {
    justify-content: space-between;
  }
`;

export const ImageDiv = styled.div`
  padding-left: 6rem;
  padding-right: 6rem;
  display: flex;
  justify-content: center;

  @media (max-width: 768px) {
    width: 10rem;
  }
`;

export const DivOptions = styled.div`
  display: flex;
  padding-left: 1rem;
  padding-right: 1rem;
  width: 65%;

  
`;

export const LinkDiv = styled.div`
  font-size: 12px;
  color: #ffffff;
  font-family: "MonteserratSemiBold";
  margin: 1rem;
  margin-right: 1.5rem;
  text-transform: uppercase;
  font-weight: 300;
  text-align: start;
  cursor: pointer;
  flex-direction: row;

  a {
    text-decoration: none;
    color: white;
  }
  :hover {
    margin-top: 12px;
    transition: 0.3s;
    transition-timing-function: ease-in;
  }
  @media (max-width: 768px) {
    color: ${theme.themeColor};
    font-family: "MonteserratSemiBold";
    font-style: normal;
    font-size: 15px;
    margin-left: 2rem; 
    a {
      text-decoration: none;
      color: ${theme.themeColor};
    }
  }
`;

export const LinkImg = styled.div`
  text-align: center;
  cursor: pointer;
  flex-direction: row;

  @media (max-width: 768px) {
    margin: 0px !important;
    margin-right: 0px !important;
    margin-top: 0px !important;
  }
`;

export const IconDiv = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-evenly;
  @media (max-width: 768px) {
    margin-top: auto!important;
    margin-bottom: 3rem;
    justify-content: end;
    svg{
      margin-left: 2rem;
    }
  }
`;

export const SocialLinks = styled.div`
  justify-content: center;
  display: flex;
  padding-left: 1rem;
  @media (max-width: 768px) {
    padding-right: 0px;
  }
`;
