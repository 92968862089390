import styled from "styled-components";
import { theme } from "../theme";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from "@material-ui/core/DialogContentText";
export const PlanMensualContainer = styled.div`
  padding-top: 1rem;
  padding-bottom: 5rem;
  img {
    width: 100%;
  }
  @media (max-width: 768px) {
    height: 15rem;
    padding-top: 5rem;
    padding-bottom: 1rem;
    img {
      height: 100%;
      object-fit: cover;
    }
  }
`;
export const KitBanner = styled.div`
  background-color: #9C3B5D;
  width: 100%;
  height: 27rem;
  margin-bottom: 5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: center center;
  background-image: url(${(props) => props.bg});
  h3{
    font-family: MonteserratB;
    font-size: 43.363px;
    color: #fff;
    text-trasform: inherit;
  }
  h6{
    font-family:BoardingPersonalUse;
    font-size:47.15px;
    color: #2E4D43;
    font-weight: 100;
  }
  a{
    border: 1px solid #9C3B5D;
    background-color: transparent;
    font-family: MonteserratB;
    margin-top:1rem
    margin-bottom:1rem
    text-transform: uppercase;
    padding:1rem;

    font-size: 20px;
    color: #fff;
    text-decoration: none;
  }
  @media (max-width: 768px) {
    h3{
      font-size: 38px;
    }
    a{
      margin-top:1.5rem;
      margin-bottom:1.5rem;
    }
    background-image: url(${(props) => props.mobilebg});
    background-size: cover;
    margin-bottom: 0;
  }

`;

export const MainDiv = styled.div`
  justify-content: space-between;
  background-color: ${theme.themeColor};
  z-index: 2;
  transform: translate(1.672 0);
  display: flex;
  height: 100vh;
  align-items: baseline;
  flex-direction: row;
  padding-left: 7.2rem;
  padding-right: 7.2rem;
  mask: radial-gradient(
        var(--r1, 90%) var(--r2, 110%) at top,
        white 79.5%,
        transparent 80%
      )
      top left,
    radial-gradient(
        var(--r1, 90%) var(--r2, 110%) at bottom,
        transparent 79.5%,
        white 80%
      )
      top center,
    radial-gradient(
        var(--r1, 90%) var(--r2, 110%) at top,
        white 79.5%,
        transparent 80%
      )
      top right;

  @media (max-width: 768px) {
    mask: none;
    height: 28rem;
    padding: 0;
    align-items: center;
  }
`;

export const ColumnIllus = styled.div`
  width: 100%;
  height: 80vh;
  background-position: right;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(${(props) => props.bg});
`;

export const ColumnText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  .Typist {
    margin-top: 2rem;
  }
  span {
    color: ${theme.white};
    font-size: 18px;
    font-family: MonteserratB;
    margin-top: 5rem;
  }
  @media (max-width: 768px) {
    align-items: center;
    .Typist {
      margin-top: 0rem;
      text-align: center;
      padding-right: 1.5rem;
      padding-left: 1.5rem;
      height: 2rem;
    }
  }
  @media (max-width: 768px) {
    img {
      width: 23rem;
    }
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  height: 80vh;
  @media (max-width: 768px) {
    justify-content: center;
    height: auto;
  }
`;

export const ColumnAboutUs = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  h2 {
    font-size: 30px;
    font-family: MonteserratB;
    color: ${theme.themeColor};
    text-transform: uppercase;
    letter-spacing: 1px;
  }
  @media (max-width: 768px) {
    padding-top: 5rem;
  }
`;

export const ProfileRows = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 4rem;
  width: 100%;
  justify-content: space-evenly;
  @media (max-width: 768px) {
    margin-top: 0;
  }
`;

export const ColumnService = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  h4,
  p {
    font-family: MontserratL;
    color: ${theme.themeColor};
    margin-top: 10px;
    font-size: 15px;
  }
  h2 {
    font-family: MonteserratB;
    color: ${theme.themeColor};
    text-transform: uppercase;
    letter-spacing: 1px;
  }
  @media (max-width: 768px) {
    margin-top: 3rem;
  }
`;

export const WaveDiv = styled.div`
  height: 75vh;
  background-size: cover;
  background-repeat: no-repeat;
  margin-top: 3rem;
  padding: 0rem;
  background-color: ${(props) => (props.mobile ? theme.themeColor : "")};
  text-align: end;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  .Typist {
    font-family: MontserratItalic;
    font-weight: normal;
    color: #fff;
    padding: 5rem;
    padding-bottom: 15rem;
    display: flex;
    font-size: 2.5rem;
    line-height: 56.17px;
    bold {
      font-family: MontserratExtraBoldItalic;
    }
    @media (max-width: 768px) {
      text-align: center;
      font-size: 1.5rem;
      padding: 0rem;
      padding-bottom: 1rem;
      line-height: 33.17px;
    }
  }
  @media (max-width: 768px) {
    height: 44vh;
    padding-right: 1rem;

    padding-left: 2rem;
  }
`;

export const ServicesRow = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 1rem 5rem 6rem;
  justify-content: space-evenly;
  .box-service {
    opacity: 1;
    visibility: visible;
    transform-origin: 50% 50% 0px;
    position: relative;
    border-radius: 12px;
    overflow: hidden;
    cursor: pointer;
    margin-top: 2rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: ${(props) => props.width};
    height: 157px;
    background: ${theme.themeColor};
    border: 1px solid ${theme.themeColor};
    h2 {
      text-align: center;
      color: #fff;
      font-family: MonteserratSemiBold;
      font-size: 18px;
      text-transform: capitalize;
    }
    @media (max-width: 768px) {
      padding-top: 0px;
      width: 207.48px !important;
    }
    @media (max-width: 1024px) {
      padding-top: 0px;
      width: 207.48px !important;
    }
  }

  .service-modal {
    position: relative;
    flex: 1 1 auto;
    width: 100%;
    height: 100%;
    background: #fff;
    border-radius: 1.2rem;
    overflow: hidden;
    pointer-events: all;
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1);
    transform-origin: 50% 50% 0px;
    padding: 2rem;
    width: 20rem;
    h5 {
      text-align: center;
      color: ${theme.themeColor};
      font-family: MonteserratB;
      font-size: 24px;
      text-transform: uppercase;
      padding-bottom: 1rem;
    }
    li {
      font-family: Montserrat;
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      text-align: left;
      color: ${theme.secundaryColor};
    }
    p {
      padding-top: 2rem;
      font-family: MonteserratB;
      font-size: 13px;
      text-align: center;
      color: ${theme.themeColor};
    }
  }
`;

export const Box = styled.div`
  width: 300px;
  height: 360px;
  background-image: url(${(props) => props.bg});
  background-size: contain;
  background-repeat: no-repeat;
`;

export const Profile = styled.div`
  text-align: center;
  :hover {
    cursor: pointer;
  }
  h3 {
    margin-top: 0.5rem;
    font-size: 20px;
    font-family: MonteserratSemiBold;
    color: ${theme.themeColor};
  }
  h4 {
    margin-top: 0.5rem;
    font-size: 20px;
    font-family: MonteserratSemiBold;
    color: ${theme.fourthColor};
    text-transform: capitalize !important;
  }
  p {
    margin-top: 0.5rem;
    font-size: 20px;
    font-family: MontserratExtraBold;
    color: ${theme.fifthColor};
    text-transform: uppercase !important;
  }
  #bg-leia {
    background-size: auto;
    width: 440px;

    @media (max-width: 768px) {
      background-size: contain;
      width: 340px;
      height: 270px !important;
    }
  }
  @media (max-width: 768px) {
    padding-top: 5rem;
  }
`;

export const TitleDialog = styled(DialogTitle)`
  font-family: "MonteserratB";
  font-size: 24px;
  text-align: center;
  color: #f5b80f;
  .MuiTypography-h6 {
    font-family: "MonteserratB";
    font-size: 24px;
    text-align: center;
    color: ${theme.themeColor};
  }
`;

export const ContentTextDialog = styled(DialogContentText)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: "MontserratItalic" !important;
  white-space: pre-wrap;
  font-size: 14px;
  line-height: 24px;
  text-align: left;
  color: ${theme.secundaryColor};
  ul {
    list-style: none;
    margin-right: 0;
    padding-left: 0;
  }

  ul > li {
    align-items: center;
    display: flex;
    margin: 5px 0;
  }

  ul li:before {
    border: 2px solid #000;
    color: transparent;
    border-radius: 100%;
    display: inline-block;
    padding: 0.25em;
    content: "•";
    line-height: 1;
    width: 5px;
    height: 5px;
    margin-right: 0.5rem;
  }
`;

export const LABContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  img {
    margin-bottom: 1rem;

    @media (max-width: 768px) {
      margin-bottom: 0rem;
      width: 100%;
      padding: 2rem;
      padding-top: 3rem;
    }
  }
  padding-bottom: 5rem;
  @media (max-width: 768px) {
    padding-bottom: 0rem;
  }
`;

export const LABInfoRow = styled.div`
  background-color: ${(props) => theme[props.color]}
  width: 100%;
  padding-top:2.5rem;
  padding-bottom:2.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  h2 {
    font-family: MonteserratB;
    color: ${theme.white};
    text-transform: uppercase;
    letter-spacing: 1px;
    @media (max-width: 768px) {
      font-size: 15px;
    }
  }

  button{
    padding-right: 60px;
    padding-left:60px;
    padding-top:20px;
    padding-bottom:20px;
    border-radius: 4px 4px 4px 4px;
    background: #fff;
    color:${theme.lografAgendaTheme};
    box-shadow: none;
    font-family: MonteserratR;
    border: none;
    font-size:20px;
    z-index:99;
    cursor:pointer;
    @media (max-width: 768px) {
      padding-right: 40px;
    padding-left: 40px;
    padding-top: 20px;
    padding-bottom: 20px;
    font-size: 15px;

    }
    &:hover{
      background: ${theme.lografAgendaTheme};
      color: #fff;
      transition: 0.5s;
    }
  }
`;
