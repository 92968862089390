import styled from "styled-components";
import { theme } from "../theme";

export const MainDiv = styled.footer`
position: absolute;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${theme.themeColor};
  font-family: "MontserratSemiBold";
  color: ${theme.white};
  padding: 1.2rem;
  height: 267px;
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin-top: 5rem;
  @media (max-width: 768px) {
    height: auto;
    margin-top:2.5rem;
  }
`;

export const ImageDiv = styled.div`

  display: flex;
  justify-content: center;
  flex-direction: column;
  img{
    width: 5rem;
  }

  @media (max-width: 768px) {
    width: 10rem;
  }
`;

export const Copyright = styled.p`
font-family: "MontserratL";
font-size: 11px;
line-height: 22px;
text-align: right;
color: #fff;
@media (max-width: 768px) {
  text-align: center;
  padding-top: 1.5rem
}
`

export const DivOptions = styled.div`
  display: flex;
  padding-left: 1rem;
  padding-right: 1rem;
  
`;

export const LinkDiv = styled.div`
font-size: 15px;
  color: #ffffff;
  font-family: "MontserratL";
  text-transform: capitalize;
  font-weight: 300;
  text-align: left;
  margin-bottom: 0.4rem;
  cursor: pointer;
  flex-direction: row;

  a {
    text-decoration: none;
    color: white;
  }
     
  @media (max-width: 768px) {
    color: #fff;
    font-family: MontserratL;
    font-style: normal;
    font-size: 15px;

    a {
      text-decoration: none;
      color: #fff;
    }
  }
`;



export const IconDiv = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-evenly;
`;

export const SocialLinks = styled.div`
display: flex;
flex-direction: column;


`
export const LinkSocial = styled.a`
font-family:"MontserratL";
font-weight: normal;
font-size: 15px;
line-height: 26px;
text-align: left;
color: #f7f7f7;
text-decoration: none;
span{
    margin-left: 1rem;
}
 
`;
export const UsefullLinks = styled.div`
    display: flex;
    flex-direction: column;
`

export const MailContact = styled.div`
display: flex;
flex-direction: column;

`
export const MailTitle = styled.p`
font-family: "MontserratL";
font-weight: normal;
font-size: 15px;
text-align: left;
color: #fff;
margin-bottom: 1rem;
@media (max-width: 768px) {
  text-align: center;
  padding-top: 1.5rem
}
`

