import React, { useState } from "react";
import Lograf from "../../assets/img/home/logo-home.png";
import HomeIllus from "../../assets/img/home/illus-home.png";
import PlanMensualImg from "../../assets/img/planmensual.jpeg";
import Typist from "react-typist";
import WebServices from "./ServicesV2/Webs";
import DesignServices from "./ServicesV2/Design";
import {
  MainDiv,
  ColumnText,
  ColumnIllus,
  Row,
  ColumnAboutUs,
  Box,
  ProfileRows,
  Profile,
  ColumnService,
  WaveDiv,
  PlanMensualContainer,
} from "./style";
import Leia from "../../assets/img/home/leia.png";
import Yisu from "../../assets/img/home/jesus.png";
import Lolitus from "../../assets/img/home/lolitus.png";
import Wave from "../../assets/img/Web/wave.png";
import AboutUsModal from "./AboutUsModal";
import { isMobile, isTablet, isIPad13 } from "react-device-detect";
const Landing = () => {
  const [open, setOpen] = useState(false);
  const [selectedStaff, setSelectedStaff] = useState({
    modalTitle: "",
    text: "",
  });
  return (
    <>
     <div style={{
         height: "100vh",
         backgroundImage: "url(/assets/img/web/work/banner.jpg)",
         backgroundPosition: "center center",
         backgroundRepeat: "no-repeat",
         backgroundSize: "contain",
         padding: "3rem",
         backgroundColor:"#FAEBF0",
     }}/>
    </>
  );
};

export default Landing;
