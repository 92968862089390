import styled from "styled-components";
import { theme } from "../theme";

export const MainDiv = styled.div`
  justify-content: center;
  align-items: center;
  z-index: 2;
  transform: translate(1.672 0);
  display: flex;
  height: 100vh;
  flex-direction: column;
  padding-left: 7.2rem;
  padding-right: 7.2rem;
  h2{
    font-family: "MontserratExtraBoldItalic";
    margin-bottom:2.5rem;
    text-align:center;
  }  
  @media (max-width: 768px) {
    mask: none;
    height: 25rem;
    padding-left: 4.2rem;
    padding-right: 4.2rem;
  }
  @media (max-width: 1024px) {
    height: 50vh;
  }
`;
