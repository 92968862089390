import styled from "styled-components";
import { theme } from "../../theme";
import CircularProgress from "@material-ui/core/CircularProgress";

export const InputMail = styled.input`
  width: 320px;
  height: 54px;
  border-radius: 0px 4px 4px 0px;
  background: transparent;
  border: 1px solid ${(props) => (props.inverted ? theme.themeColor : `#fff`)};
  font-family: "MonteserratB";
  color: ${(props) => (props.inverted ? theme.themeColor : `#fff`)};
  padding: 1rem;
  &::placeholder {
    color: ${(props) => (props.inverted ? theme.themeColor : `#fff`)};
  }
  :focus {
    outline: 0;
  }
  box-shadow: none!important;
  @media (max-width: 768px) {
    width: 66.555555555555%;
  }
`;

export const ButtonMail = styled.button`
  width: 91px;
  height: 54px;
  border-radius: 0px 4px 4px 0px;
  background: ${(props) => (props.inverted ? theme.themeColor : `#fff`)};
  color: ${(props) => (props.inverted ? `#fff` : theme.themeColor)};
  border-color: ${(props) => (props.inverted ? theme.themeColor : `#fff`)};
  box-shadow: none;
  border: 2px solid ${(props) => (props.inverted ? theme.themeColor : `#fff`)};
  .MuiCircularProgress-root {
    width: 25px !important;
    height: 25px !important;
  }
  .MuiCircularProgress-colorPrimary  {
    color: ${theme.themeColor};
  }
  margin-left: -5px;
  margin-bottom: 1px;
  cursor: pointer;
  :focus {
    outline: 0;
  }
  @media (max-width: 768px) {
    height: 53.55555555555px;
  }
`;



export const CircularSpinner = styled(CircularProgress)`
  .MuiCircularProgress {
    .root {
    }
    color: ${theme.themeColor};
  }
`;
