import styled from "styled-components";
import { theme } from "../theme";

export const MainDiv = styled.div`
  justify-content: space-between;
  background-color: ${theme.themeColor};
  z-index: 2;
  transform: translate(1.672 0);
  display: flex;
  height: 100vh;
  align-items: baseline;
  flex-direction: column;
  padding-left: 7.2rem;
  padding-right: 7.2rem;
  #circles {
    padding: 15rem;
    padding-top: 8rem;
    @media (max-width: 1024px) {
      padding: 0px;
      padding-bottom: 2rem;
    }
  }
  mask: radial-gradient(
        var(--r1, 90%) var(--r2, 110%) at top,
        white 79.5%,
        transparent 80%
      )
      top left,
    radial-gradient(
        var(--r1, 90%) var(--r2, 110%) at bottom,
        transparent 79.5%,
        white 80%
      )
      top center,
    radial-gradient(
        var(--r1, 90%) var(--r2, 110%) at top,
        white 79.5%,
        transparent 80%
      )
      top right;

  @media (max-width: 768px) {
    mask: none;
    height: 25rem;
  }
  @media (max-width: 1024px) {
    height: 50vh;
  }
`;

export const ColumnIllus = styled.div`

  @media (max-width: 1024px) {
    img {
      width: 25rem;
    }
  }
`;

export const ColumnText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  .Typist {
    margin-top: 2rem;
  }
  span {
    color: ${theme.white};
    font-size: 18px;
    font-family: MonteserratB;
    margin-top: 5rem;
  }
  img {
    width: 15rem;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding-top: 5rem;
  align-items: center;

  @media (max-width: 768px) {
    justify-content: center;
  }
`;

export const AboutColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-right: 5rem;
  padding-left: 5rem;
  padding-top: 10rem;
  @media (max-width: 768px) {
    padding-right: 2rem;
    padding-left: 2rem;
    padding-top: 5rem;
  }
`;

export const AboutRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 1rem;
  #admin-mockup {
    margin-right: 0;
    margin-left: -60px;
    width: 45rem;
  }
  img {
    margin-right: -5rem;
  }

  @media (max-width: 768px) {
    flex-wrap: wrap;
    img,
    #admin-mockup {
      margin-right: 0;
      margin-left: 0;
      margin-bottom: 1rem;
      width: 20rem;
    }
  }
`;

export const Text = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  b{
    font-family: MonteserratB;
  }
  h2 {
    font-family: MonteserratB;
    font-size: 30px;
    line-height: 12px;
    text-align: left;
    color: ${theme.themeColor};
    margin-bottom: 3rem;
  }
  p {
    font-family: MontserratL;
    font-size: 16px;
    line-height: 29px;
    text-align: left;
    color: ${theme.secundaryColor};
  }
  @media (max-width: 768px) {
    h2 {
      margin-bottom: 1.5rem;
    }
    margin-bottom: 2rem;
    p,
    h2 {
      text-align: center;
    }
    p{
      font-size: 15px;
    }
    padding-right:0px!important;
    padding-left:0px!important;
  }
`;

export const Mockup = styled.div`
  background-image: url(${(theme) => theme.background});
  background-repeat: no-repeat;
  width: 65%;
  height: 40rem;
`;

export const LografEcommercePack = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 5rem;
  p{
    margin-top:1rem;
    font-family: MonteserratB;
    font-size: 1.5rem;
  }
  h2 {
    font-family: MonteserratB;
    font-size: 30px;
    line-height: 12px;
    text-align: left;
    color: ${theme.themeColor};
    margin-bottom: 3rem;
  }
  @media (max-width: 768px) {
    
    h2 {
        text-align: center;
        font-size: 25px;
        line-height: 30px;
      
    }
  
}
`;

export const BoxContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 60%;
  margin-top: 2rem;
  justify-content: space-evenly;
  @media (max-width: 768px) {
    margin-right: 0rem;
    margin-bottom: 2.5rem;
    width: 100%;
    flex-wrap: wrap;
  }
`;

export const Box = styled.div`
width: 19rem;
  background: #fff;
  border: 2px solid ${theme.themeColor}
  display: flex;
  border-radius:10px;
  flex-direction: column;

  div {
    padding-top: 3rem;
    padding-right: 3.5rem;
    padding-left: 3.5rem;
    h5,
    h1,
    h2 {
      font-size: 25px;
      line-height:30px;
      text-align: center;
      font-family: MonteserratB;
      color: ${theme.themeColor}

      @media (max-width: 768px) {
              text-align: center;
              font-size: 25px;
              line-height: 30px;
            
      }
    }
    p {
      font-family: MontserratL;
      font-size: 16px;
      text-align: left;
      color: ${theme.secundaryColor}
    }
  }
  @media (max-width: 768px) {
    margin-bottom: 1rem;
  
}
  

`;


export const ColumnWorks = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
width: 100%;
margin-top:8rem;
h2 {
  font-size: 30px;
  font-family: MonteserratB;
  color: ${theme.themeColor};
  text-transform: uppercase;
  letter-spacing: 1px;
}
@media (max-width: 768px) {
  padding-top: 5rem;
  a{
    width:100%;
  }
  h2{
    margin-bottom:2rem;
  }
}
`

export const BoxWork = styled.div`
width: 400px;
height: 265px;
background-image: url(${props => props.bg});
background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
@media (max-width: 768px) {
  margin-bottom: 1rem;
  background-repeat:no-repeat;
  width:100%;
}

`

export const RowWork = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
    justify-content: space-evenly;
    margin-bottom: 1rem;
    @media (max-width: 768px) {
      flex-wrap: wrap;
      width:100%;
    }
    
`

export const Grid = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5rem;
  width: 100%;
  @media (max-width: 768px) {
    padding: 0rem;
  }
  
`
