import styled from "styled-components";
import InputBase from "@material-ui/core/InputBase";
import {theme} from '../../theme';

export const Box = styled.div`
  width: 35rem;
  margin-top: 2rem;
  border: 3px solid ${theme.themeColor};
  padding: 2rem;
  display: -webkit-flex;
  flex-direction: column;
  align-items: flex-end;
  margin-bottom: 2rem;

  @media (max-width: 768px) {
    width: 23rem;
  }
`;

export const InputField = styled(InputBase)`
  color: white;
  height: 80px;
  border: 3px solid #F1B43C;
  box-sizing: border-box;
  font-family: MontserratL!important;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  padding-left: 2rem
  text-align: center;
  width: 100%;
  color: #000000;
  margin-bottom: 0.5rem;
`;

export const TextArea = styled(InputBase)`
  overflow: scroll;
  height: 8rem;
  border: 3px solid ${theme.themeColor};
  padding-left: 2rem !important;
  padding-top: 2rem !important;
  padding-right: 2rem !important;
  font-family: MontserratL !important;
  font-style: normal !important;
  font-weight: 300 !important;
  font-size: 18px !important;
  text-align: center;
  width: 100%;
  margin-bottom: 0.5rem;
  color: rgba(0, 0, 0, 0.87) !important;
  cursor: text;
  display: block !important;
  position: initial !important;
  box-sizing: border-box !important;
  align-items: normal !important;
  line-height: 1.1875em !important;
  letter-spacing: 0.00938em !important;
`;

export const Button = styled.button`
  background: ${theme.themeColor};
  font-family: MonteserratR;
  width: 201px;
  height: 40px;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */
  cursor: pointer;
  outline: none;
  text-align: center;
border: 0px;
  color: #ffffff;
`;

export const FormContainer = styled.form`
  width: 100%;
  flex-direction: column;
  align-items: flex-end;
  display: flex;
`;
