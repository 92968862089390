import styled from "styled-components";

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  width: 100%;
  margin-top: 60px;
  padding-left: 8rem;
  padding-right: 8rem;
  flex-wrap:wrap;
  @media (max-width: 768px) {
    padding-right: 0;
    padding-left: 0;
    margin-top: 20px;
  }
`;

export const ServiceBox = styled.div`
  border: 3px solid ${(props) => props.color};
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.color};
  justify-content: center;
  align-items: center;
  width: 30%;
  height: 100%;
  height: 50vh;
  margin: 1rem;
  position: relative;
  .back-component {
    display: none;
  }
  &:hover {
    border: 0px;
    .front-component {
      display: none;
      transition: 1s;
    }
    .back-component {
      display: inherit;
    }
  }
  h3 {
    position: absolute;
    top: 0;
    left: 0;
    margin-left: 2rem;
    margin-top: 2rem;
  }

  @media (max-width: 768px) {
    width: 100%;
    height:60vh
    padding: 10px;
    border-radius: 0px;
    margin-bottom: 20px;
  }
`;
export const WhiteContainer = styled.div`
  background-color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  width: 90%;
  height: 90%;
  border-bottom-right-radius: 100%;
  svg {
    width: 75%;
    margin-left: 2rem;
    margin-top: 2rem;
    fill: inherit;
  }
  @media (max-width: 768px) {
    svg{
      width: 50%;
    }
  }
`;

export const Logo = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  svg {
    margin-left: 2rem;
    margin-top: 2rem;
  }
`;

export const Icon = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  text-align: center;
  svg {
    fill: #fff;
    width: 50%;
    
  }

  @media (max-width: 768px) {
    text-align: end;
    margin-right:2rem;
    width:40%;
  }
`;

export const BackComponent = styled.div`
  display: inherit;
  background-color: ${(props) => props.color};
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  a{
    margin-top: auto;
    margin-bottom: auto;
  }
  button {
    text-align: start;
    font-size: 1.5rem;
    padding: 1rem;
    color: #fff;
    background-color: transparent;
    border: 1.2px solid #fff;
    margin-top: auto;
    margin-bottom: auto;
    b {
      font-family: MonteserratB;
    }

    &:hover {
        cursor: pointer;
        background-color: #fff;
        color: ${(props) => props.color};
        transition: 0.5s;
      
    }   
  }
`;

export const FrontComponent = styled.div`
  width: 100%;
  height: 100%;
`;

export const BackLogo = styled.div`
    text-align: center;

    svg{
        width: 80%;
    }
`