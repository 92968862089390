import styled from 'styled-components';
import {theme} from '../../theme';


export const ColumnProcess = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 5rem;
  h2 {
    font-size: 30px;
    font-family: MonteserratB;
    color: ${theme.themeColor};
    text-transform: uppercase;
    letter-spacing: 1px;
  }
  @media (max-width: 768px) {
    padding-top: 5rem;
  }
`;


export const RowProcess = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 4rem;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 11rem;
  @media (max-width: 768px) {
    margin-top: 0;
    height: auto;
  }
`;

export const Process = styled.div`
  text-align: center;
  margin-right: 2rem;
  margin-left: 2rem;

  h3 {
    margin-top: 1.5rem;
    font-size: 20px;
    font-family: MonteserratSemiBold;
    color: ${theme.themeColor};
  }
  p {
    margin-top: 1.5rem;
    font-size: 15px;
    font-family: MonteserratSemiBold;
    color: ${theme.secundaryColor};
  }
  
  @media (max-width: 768px) {
    width: 100%;
    padding-top: 5rem;
  }
`;

export const Separator = styled.div`
  width: 18px;
  height: 18px;
  border: 2px solid #000;
  border-radius: 100px;
  background-color: ${theme.fourthColor};
  @media (max-width: 768px) {
    display: none;
  }
`;
