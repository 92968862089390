import styled from "styled-components";

export const MainDiv = styled.div`
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #f0f0f0;
  position: relative;
  z-index: 2;
`;

export const MainDivLowResolution = styled.div`
padding-top: 2rem;
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  background-color:#f0f0f0;
  position: relative;
  z-index: 1;
`;

export const Title = styled.p`
  font-family: MonteserratR;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 42px;
  width: 23rem;
  color: #000000;

  @media (max-width: 768px) {
    font-size: 15px;
    line-height: 18px;
    width: fit-content;
  }
`;

export const Subtitle = styled.p`
  font-family: MontserratL;
  font-style: normal;
  font-weight: 300;
  color: #000000;
  font-size: 17px;
  line-height: 22px;
  width: 26rem;

  @media (max-width: 768px) {
    font-size: 17px;
    line-height: 22px;
    width: fit-content;
  }
`;

export const DivBoxText = styled.div`
  width: 100%;
  display: flex;
  -webkit-box-align: baseline;
  justify-content: space-between;
  align-items: baseline;
  flex-wrap: wrap;
`;

export const DivText = styled.div`
  padding-right: 4rem;
  padding-left: 7rem;

  @media (max-width: 768px) {
    text-align: center;
    padding-left: none;
    padding-right: none;
    padding: 1rem;
  }
`;

export const ColumnDiv = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 768px) {
    width: 100%;
    align-items: center;
  }
`;
