import styled from "styled-components";
import { theme } from "../../theme";

export const Technologies = styled.div`
  height: 100vh;
  background-repeat: no-repeat;
  margin-top: 10rem;
  padding: 5rem;
  padding-bottom: 0px;
  background-color: ${(props) => (props.mobile ? theme.themeColor : "")};

  @media (max-width: 768px) {
    padding-right: 2rem;
    padding-left: 2rem;
    margin-bottom:5rem;
    height: 132vh;
  }
`;

export const TechnologiesDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: end;
  }
`;

export const TechnologiesIconRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-self: right;
  width: 45%;
  justify-content: space-around;
  @media (max-width: 768px) {
    width: fit-content;
    flex-wrap: wrap;
  }
`;

export const TechnologiesTextColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    font-size: 30px;
    font-family: MonteserratB;
    color: ${theme.white};
    text-transform: uppercase;
    letter-spacing: 1px;
  }
  p {
    margin-top: 3rem;
    font-family: MontserratL;
    font-weight: 600;
    font-size: 20px;
    line-height: 36px;
    text-align: left;
    color: #fff;
  }
  @media (max-width: 768px) {
    align-items: center;
    h2 {
      font-size: 20px;
    }
    p {
      margin-top: 1rem;
      font-family: MontserratL;
      font-weight: 600;
      font-size: 15px;
      line-height: 27px;
      text-align: left;
      color: #fff;
    }
  }
`;

export const TechnologiesIconMain = styled.div`
  display: flex;
  width: 80%;
  flex-direction: column;
  align-items: flex-end;

  #lower {
    margin-top: 1rem;
    width: 40%;
  }
  @media (max-width: 768px) {
    flex-direction: row;
    align-items: center;
    width: fit-content!important;
    flex-wrap: wrap;
    #lower{
      margin-top: 0px;
      flex-direction: row;
      align-items: center;
      flex-wrap: wrap;
      width: fit-content;
    }
    padding-top: 5rem;
    svg{
      margin-bottom: 1rem;
      width: 5rem;
    }
  }
    
`;
