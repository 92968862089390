import React, { Suspense } from "react";
import Header from "./Header";
import Landing from "./Landing";
import Footer from "./Footer";
import Web from "./Web";
import Ecommerce from "./Ecommerce";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Contact from './InfoContact';
import Gift from './Gift';

import { createGlobalStyle } from "styled-components";
const Design = React.lazy(() => import("./Design"));

const GlobalStyles = createGlobalStyle`
  body{
    background-color: #FDFDFD;
  }
  @font-face {
    font-family: MontserratL;
    src: url(${require("./../assets/fonts/Montserrat-Light.ttf")});
  }
  @font-face {
    font-family: MonteserratR;
    src: url(${require("./../assets/fonts/Montserrat-Regular.otf")});
  }
  @font-face {
    font-family: MonteserratB;
    src: url(${require("./../assets/fonts/Montserrat-Bold.otf")});
  }
  @font-face {
    font-family: MonteserratSemiBold;
    src: url(${require("./../assets/fonts/Montserrat-SemiBold.ttf")});
  }
  @font-face{
    font-family: MontserratExtraBoldItalic;
    src:url(${require("./../assets/fonts/Montserrat-ExtraBoldItalic.ttf")});
  }
  @font-face{
    font-family: MontserratExtraBold;
    src:url(${require("./../assets/fonts/Montserrat-ExtraBold.ttf")});
  }
  @font-face{
    font-family: MontserratItalic;
    src:url(${require("./../assets/fonts/Montserrat-Italic.ttf")});
  }
  @font-face{
    font-family: BoardingPersonalUse;
    src:url(${require("./../assets/fonts/BoardingPersonalUse.ttf")});
  }
  @font-face{
    font-family: Goeslim;
    src:url(${require("./../assets/fonts/Goeslim-Regular.otf")});
  }

  .italic{
    text-transform: capitalize!important;
    margin-right:5px;
    font-size: 30px;
    @media (max-width: 768px) {
      font-size: 15px;
    }
  }
  .regular-italic{
    font-family: MontserratItalic!important;
    text-transform: lowercase!important;
    font-size: 30px;
    @media (max-width: 768px) {
      font-size: 30px;
    }
  }
  .bold{
    font-family: MonteserratB!important;
    font-size: 30px;
    @media (max-width: 768px) {
      font-size: 30px;
    }
  }
  .goeslim{
    font-family: Goeslim!important;
    font-size: 1.6rem;
  }
  .light-italic{
    font-family: MontserratL!important;
    text-transform: none!important;
    font-style: italic;
    font-size: 30px;
    @media (max-width: 768px) {
      font-size: 15px;
    }
  }
`;

function App() {
  return (
    <Router>
      <Switch>
        <div className="App">
          <GlobalStyles />
          <Route exact path="/">
            <Landing />
          </Route>
        
          


        </div>
      </Switch>
    </Router>
  );
}

export default App;
