import styled from "styled-components";
import {theme} from '../../theme';
export const Box = styled.div`
  background-color: transparent;
  display: flex;
  flex-direction: column;
  padding: 2rem;
  height: 14rem;
    padding-right: 2rem;
    padding-left: 2rem;
    justify-content: space-around;
    align-items: center;
`;

export const TextDiv = styled.div`
  width: 100%;
  color: ${theme.themeColor};
  font-family: "MontserratL";
  font-weight: 300;
`;

export const Text = styled.p`
    margin: 0px;
    font-size: 17px;
    Line Height: 21px
    margin-bottom: 0.5rem;

    .strong{
        font-family: MonteserratB!important;
    }
`;

export const TextTitle = styled.p`
  color: #ffffff;
  font-family: "MonteserratB";
  font-weight: 300;
`;

export const IconDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;

  img {
    cursor: pointer;
  }
`;
