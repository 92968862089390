import styled from 'styled-components';
// import pelaPhoto from 'assets/servicos-page/pelaPhoto.png';
// import HeaderBG from 'assets/headerBG.png';
import { theme } from "../../theme";
import { CarouselProvider, DotGroup, Slide, Slider } from 'pure-react-carousel';

export const MainDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${theme.themeColor}

  padding: 3rem;
}
`;

export const Title = styled.p`
  font-family: MonteserratB;
  font-size: 30px;
  line-height: 12px;
  /* identical to box height, or 129% */

  color: #fff;
 
`;

export const Testimonie = styled.p`
  font-family: MonteserratR;
  font-style: normal;
  font-weight: normal;
  line-height: 23px;
  font-size: 18px;
  width: 36rem;
  margin-top: 1rem;
  margin-bottom: 2rem;
  text-align: center;

  @media (max-width: 768px) {
    width: 100%;
    font-size: 13px;
    line-height: 23px;
    text-align: center;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    padding: 0 1rem;
  }
  color: #fff;
`;

export const ImageCircle = styled.div`
  height: 6rem;
  width: 6rem;
  border-radius: 46px;
  background-color: #fff;

`;

export const Name = styled.p`
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  /* identical to box height, or 150% */

  color: #554d89;
  margin-bottom: 0px;

  @media (max-width: 768px) {
    font-size: 19px;
    line-height: 26px;
  }
`;

export const Company = styled.p`
font-family: MonteserratR;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
  /* identical to box height, or 131% */

  color: #fff;

  mix-blend-mode: normal;

  @media (max-width: 768px) {
    font-size: 15px;
    line-height: 20px;
  }
`;

export const StyledCarousel = styled(CarouselProvider)`
  width: 36rem;
  margin-top: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .slideInner___2mfX9 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: fit-content;
    height: fit-content;
  }

  @media (max-width: 768px) {
    width: 100vw;
    height: 20rem;
  }
`;

export const StyledSlider = styled(Slider)`
  width: 36rem;
  justify-content: flex-start;
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    width: 100vw;
    height: 20rem;
  }
`;

export const StyledSlide = styled(Slide)`
  .carousel__slide-focus-ring {
    display: none;
  }

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const StyledDotGroup = styled(DotGroup)`
 display: none;
`;
